<!--
  This view should never be used. It is just useful to occupy the first argument in vueRouter.
  All api calls  are completed within beforeEnter guard in vueRouter.
  Any request to this url is always redirected, after the Verification call is done.
-->
<template>
  <v-container />
</template>

<script>
  export default {
    name: 'Verify',
    props: {
      order: Object,
    },
    data: () => ({}),
    methods: {},
  }
</script>
